.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  z-index: 0;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #ccc;
  color: #ccc;
  margin-bottom: 6px;
}

.step-counter {
  cursor: pointer;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.active>.step-counter {
  background-color: #fff;
  color: #2CFFEA;
  border-color: #2CFFEA;
}


.stepper-item.completed .step-counter {
  background-color: #2CFFEA;
  color: #fff;
  border-color: #2CFFEA;
}

.stepper-item.completed,
.stepper-item.active>.step-name {
  color: #2CFFEA;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #2CFFEA;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}


#contentTabs {
  width: 100%;
  margin-top: 10px;
}